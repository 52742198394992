
// import React, { useState, useContext, useEffect } from 'react';
// import AuthContext from './AuthProvider';
// import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import axios from 'axios';
// import image from '../Images/RapportSoftlogo.png';
// import './Style.css';
// import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi/";
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';
// import SplitButton from 'react-bootstrap/SplitButton';
// import { Link, useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBuilding, faUser, faSignOutAlt, faUserCheck, faUserCircle, faUserGear, faUserPlus, faUserShield, faUsersRays, faRefresh } from '@fortawesome/free-solid-svg-icons';
// import { faStar } from '@fortawesome/free-solid-svg-icons';
// import { FaUserShield } from 'react-icons/fa';


// export default function Head() {

//   const {
//     jwtToken,
//     userId,
//     username,
//     branchId,
//     companyid,
//     isAuthenticated,
//     role,
//     companyname,
//     branchname,
//     login,
//     logout,
//   } = useContext(AuthContext);



//   const [parentMenus, setParentMenus] = useState([]);
//   const [collapsed, setCollapsed] = useState(false);
//   const [toggled, setToggled] = useState(false);
//   const [allowedProcessIds, setAllowedProcessIds] = useState([]);
//   const navigate = useNavigate();




//   // If the user is not authenticated, redirect to the login page
//   useEffect(() => {
//     if (!isAuthenticated) {
//       navigate('/login?message=You need to be authenticated to access this page.');
//     }
//   }, [isAuthenticated, navigate]);

//   const handleLogout = () => {
//     logout();
//     navigate('/login?message2=You have successfully logged out.');
//   };


//   const myStyle = {
//     height: '40px',
//   };

//   const handleAddClick = () => {
//     navigate(`/parent/changepassword`);
//   };
//   return (
//     <div>

//       <Navbar



//         // rushi helped a lot
//         style={{
//           background: '#26a69a',
//           boxShadow: '0px 5px 10px rgba(0, 77, 64, 0.3)',
//           border: '1px solid rgba(0, 0, 0, 0.3)',
//           borderRadius: '0',
//           backgroundColor: '#85144b',
//           backgroundImage: 'linear-gradient(15deg, #000000 3%,    #00796b 30%,  #00897b 10%,   #00695c 80%  )',
//           backgroundColor: 'rgba(0, 0, 0, 0.3)',
//           backgroundImage: 'url("https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//         }}




//       >
//         <Container>
//           {/* <Navbar.Brand href="#home" className="ml-auto" style={{ marginLeft: '-46px', }} >
//   <img
//      src="https://raw.githubusercontent.com/ShubhamDeshmukh18/AshteLogistics/main/assets/img/logo%20(1).png"
//     alt="DGDC Logo"
//     height="30"
//   />
// </Navbar.Brand> */}


//           <Navbar.Brand href="#home" className="ml-auto" style={{ marginLeft: '-46px', }} >
//             <img
//               src="https://raw.githubusercontent.com/ShubhamDeshmukh18/AshteLogistics/main/assets/img/logo%20(1).png"
//               alt="DGDC Logo"
//               height="30"
//             />
//             <div style={{ color: '#643D00', fontWeight: 'bold', fontSize: '13px', marginTop: '5px', marginLeft: '8px', paddingBottom: '0', lineHeight: '1' }}>
//               <strong>{branchname}</strong>
//             </div>
//           </Navbar.Brand>

//           <SplitButton
//             key='start'
//             id={`dropdown-button-drop-start`}
//             drop='start'
//             variant="tertiary"
//             // style={{ position: 'absolute', top: '50px', left: '20px', fontSize: '10px', borderRadius: '20px' }}
//             title={
//               <div style={{ fontSize: '16px', fontFamily: 'Your-Heading-Font', marginRight: '-15px', fontWeight: 'bold', color: '#fff' }}>
//                 <FontAwesomeIcon icon={faUserGear} style={{ color: 'orange' }} /> {userId}
//               </div>
//             }
//             className='custom-dropdown'
//             style={{ fontSize: '10px', borderRadius: '20px', top: '10px', left: '52px', color: '#fff' }}
//           >


//             <Dropdown.Item eventKey="1">
//               <a className="dropdown-item" href="#" style={{ fontSize: '12px', border: '1px solid #ccc', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
//                 <FontAwesomeIcon icon={faBuilding} style={{ color: 'blue' }} /> <span className='fontwt'>Company &nbsp;</span> {companyname}
//               </a>
//             </Dropdown.Item>
//             <Dropdown.Item eventKey="2">
//               <a className="dropdown-item" href="#" style={{ fontSize: '12px', border: '1px solid #ccc', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
//                 <FontAwesomeIcon icon={faBuilding} style={{ color: 'green' }} /> <span className='fontwt'>Branch &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {branchname}
//               </a>
//             </Dropdown.Item>
//             <Dropdown.Item eventKey="3">
//               <a className="dropdown-item" href="#" style={{ fontSize: '12px', border: '1px solid #ccc', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
//                 <FontAwesomeIcon icon={faUser} style={{ color: 'purple' }} /> <span className='fontwt'>User </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{username}
//               </a>
//             </Dropdown.Item>

//             <Dropdown.Item eventKey="4">
//               <button className="btn btn-success" onClick={handleAddClick} style={{ fontSize: '12px', marginRight: 20 }}>
//                 <FontAwesomeIcon icon={faRefresh} /> Change Password
//               </button>
//               <button className="btn btn-danger" onClick={handleLogout} style={{ fontSize: '12px' }}>
//                 <FontAwesomeIcon icon={faSignOutAlt} /> Logout
//               </button>
//             </Dropdown.Item>
//           </SplitButton>


//         </Container>
//       </Navbar>


//     </div>
//   )
// }



import React, { useState, useContext, useEffect } from 'react';
import AuthContext from './AuthProvider';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import image from '../Images/RapportSoftlogo.png';
import './Style.css';
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi/";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Dropdown from 'react-bootstrap/Dropdown';
import SplitButton from 'react-bootstrap/SplitButton';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser, faSignOutAlt, faUserCheck, faUserCircle, faUserGear, faUserPlus, faUserShield, faUsersRays, faRefresh, faBell, faTimes, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { Card, CardBody, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import Table from 'react-bootstrap/Table';
import Rate_Chart_Service from "../services/Rate_Chart_Service";
import { Pagination } from "react-bootstrap";

export default function Head() {

  const {
    jwtToken,
    userId,
    username,
    branchId,
    companyid,
    isAuthenticated,
    role,
    companyname,
    branchname,
    login,
    logout,
    logintype,
    logintypeid,
    userType
  } = useContext(AuthContext);



  const [parentMenus, setParentMenus] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [allowedProcessIds, setAllowedProcessIds] = useState([]);
  const navigate = useNavigate();




  // If the user is not authenticated, redirect to the login page
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login?message=You need to be authenticated to access this page.');
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = () => {
    logout();
    navigate('/login?message2=You have successfully logged out.');
  };
  // useEffect(() => {
  //   if (logintype === 'CHA'|| logintype === 'Party') {
  //     handleOpenCreditLimitModel();
  //   }
  // }, []);

  const myStyle = {
    height: '40px',
  };

  const handleAddClick = () => {
    navigate(`/parent/changepassword`);
  };

  const [show, setShow] = useState(false);
  const [creditLimitRecords, setCreditLimitRecords] = useState([]);

  const handleOpenCreditLimitModel = async () => {
    try {
      setCurrentPage(1);
      const response = await Rate_Chart_Service.getPartiesCreditLimits(companyid, branchId, logintype, logintypeid);
      setCreditLimitRecords(response.data);
      
      setShow(true);
    } catch (error) {
      // Log the error for debugging
      console.error("Error fetching credit limit records:", error);
    }
  };
  

  const handleClose = () => {
    setShow(false);
    setCreditLimitRecords([]);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentfilteredImports = creditLimitRecords.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(creditLimitRecords.length / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const displayPages = () => {
    const centerPageCount = 5;
    const middlePage = Math.floor(centerPageCount / 2);
    let startPage = currentPage - middlePage;
    let endPage = currentPage + middlePage;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, centerPageCount);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - centerPageCount + 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };



  return (
    // <div>

    //   <Navbar
    //     style={{
    //       background: '#26a69a',
    //       boxShadow: '0px 5px 10px rgba(0, 77, 64, 0.3)',
    //       border: '1px solid rgba(0, 0, 0, 0.3)',
    //       borderRadius: '0',
    //       backgroundColor: '#85144b',
    //       backgroundImage: 'linear-gradient(15deg, #000000 3%,    #00796b 30%,  #00897b 10%,   #00695c 80%  )',
    //       backgroundColor: 'rgba(0, 0, 0, 0.3)',
    //       backgroundImage: 'url("https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
    //       backgroundSize: 'cover',
    //       backgroundPosition: 'center',
    //     }}




    //   >
    //     <Container>

    //       <Navbar.Brand href="#home" className="ml-auto" style={{ marginLeft: '-46px', }} >
    //         <img
    //           src="https://raw.githubusercontent.com/ShubhamDeshmukh18/AshteLogistics/main/assets/img/logo%20(1).png"
    //           alt="DGDC Logo"
    //           height="30"
    //         />
    //         <div style={{ color: '#643D00', fontWeight: 'bold', fontSize: '13px', marginTop: '5px', marginLeft: '8px', paddingBottom: '0', lineHeight: '1' }}>
    //           <strong>{branchname}</strong>
    //         </div>
    //       </Navbar.Brand>

    //       <div style={{ marginRight: '1px', cursor: 'pointer' }}>
    //         <FontAwesomeIcon
    //           icon={faBell}
    //           style={{
    //             fontSize: '20px',
    //             color: '#fff',
    //             marginTop: '10px',
    //           }}
    //         />
    //       </div>


    //       <SplitButton
    //         key='start'
    //         id={`dropdown-button-drop-start`}
    //         drop='start'
    //         variant="tertiary"
    //         title={
    //           <div style={{ fontSize: '16px', fontFamily: 'Your-Heading-Font', marginRight: '-15px', fontWeight: 'bold', color: '#fff' }}>
    //             <FontAwesomeIcon icon={faUserGear} style={{ color: 'orange' }} /> {userId}
    //           </div>
    //         }
    //         className='custom-dropdown'
    //         style={{ fontSize: '10px', borderRadius: '20px', top: '10px', left: '52px', color: '#fff' }}
    //       >


    //         <Dropdown.Item eventKey="1">
    //           <a className="dropdown-item" href="#" style={{ fontSize: '12px', border: '1px solid #ccc', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
    //             <FontAwesomeIcon icon={faBuilding} style={{ color: 'blue' }} /> <span className='fontwt'>Company &nbsp;</span> {companyname}
    //           </a>
    //         </Dropdown.Item>
    //         <Dropdown.Item eventKey="2">
    //           <a className="dropdown-item" href="#" style={{ fontSize: '12px', border: '1px solid #ccc', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
    //             <FontAwesomeIcon icon={faBuilding} style={{ color: 'green' }} /> <span className='fontwt'>Branch &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {branchname}
    //           </a>
    //         </Dropdown.Item>
    //         <Dropdown.Item eventKey="3">
    //           <a className="dropdown-item" href="#" style={{ fontSize: '12px', border: '1px solid #ccc', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
    //             <FontAwesomeIcon icon={faUser} style={{ color: 'purple' }} /> <span className='fontwt'>User </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{username}
    //           </a>
    //         </Dropdown.Item>

    //         <Dropdown.Item eventKey="4">
    //           <button className="btn btn-success" onClick={handleAddClick} style={{ fontSize: '12px', marginRight: 20 }}>
    //             <FontAwesomeIcon icon={faRefresh} /> Change Password
    //           </button>
    //           <button className="btn btn-danger" onClick={handleLogout} style={{ fontSize: '12px' }}>
    //             <FontAwesomeIcon icon={faSignOutAlt} /> Logout
    //           </button>
    //         </Dropdown.Item>
    //       </SplitButton>


    //     </Container>
    //   </Navbar>


    // </div>


    <div>
      <Navbar
        style={{
          background: '#26a69a',
          boxShadow: '0px 5px 10px rgba(0, 77, 64, 0.3)',
          border: '1px solid rgba(0, 0, 0, 0.3)',
          borderRadius: '0',
          backgroundColor: '#85144b',
          backgroundImage: 'linear-gradient(15deg, #000000 3%, #00796b 30%, #00897b 10%, #00695c 80%)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          backgroundImage: 'url("https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container>
          <Navbar.Brand href="#home" className="ml-auto" style={{ marginLeft: '-46px' }}>
            <img
              src="https://raw.githubusercontent.com/ShubhamDeshmukh18/AshteLogistics/main/assets/img/logo%20(1).png"
              alt="DGDC Logo"
              height="30"
            />
            <div
              style={{
                color: '#643D00',
                fontWeight: 'bold',
                fontSize: '13px',
                marginTop: '5px',
                marginLeft: '8px',
                paddingBottom: '0',
                lineHeight: '1',
              }}
            >
              <strong>{branchname}</strong>
            </div>
          </Navbar.Brand>

          <div style={{ display: 'flex', alignItems: 'center' }}>


            {logintype !== 'Carting Agent' && logintype !== 'Console' && userType !== 'Cargo Custodian' && userType !== 'Cargo Gate' && userType !== 'Cargo Official' && logintype !== 'Party' && logintype !== 'CHA' && (
              <div style={{ position: 'relative', marginRight: '15px', cursor: 'pointer', marginTop: '17px' }}>
                <FontAwesomeIcon
                  icon={faBell}
                  onClick={handleOpenCreditLimitModel}
                  style={{
                    fontSize: '20px',
                    color: '#fff',
                  }}
                />
                {/* Red dot */}
                <div
                  style={{
                    position: 'absolute',
                    top: '-5px',
                    right: '-5px',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                  }}
                />
              </div>
            )}

            {/* SplitButton */}
            <SplitButton
              key="start"
              id={`dropdown-button-drop-start`}
              drop="start"
              variant="tertiary"
              title={
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Your-Heading-Font',
                    marginRight: '-15px',
                    fontWeight: 'bold',
                    color: '#fff',
                  }}
                >
                  <FontAwesomeIcon icon={faUserGear} style={{ color: 'orange' }} /> {userId}
                </div>
              }
              className="custom-dropdown"
              style={{
                fontSize: '10px',
                borderRadius: '20px',
                top: '10px',
                left: '52px',
                color: '#fff',
              }}
            >
              <Dropdown.Item eventKey="1">
                <a
                  className="dropdown-item"
                  href="#"
                  style={{
                    fontSize: '12px',
                    border: '1px solid #ccc',
                    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <FontAwesomeIcon icon={faBuilding} style={{ color: 'blue' }} /> <span className="fontwt">Company &nbsp;</span> {companyname}
                </a>
              </Dropdown.Item>
              <Dropdown.Item eventKey="2">
                <a
                  className="dropdown-item"
                  href="#"
                  style={{
                    fontSize: '12px',
                    border: '1px solid #ccc',
                    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <FontAwesomeIcon icon={faBuilding} style={{ color: 'green' }} /> <span className="fontwt">Branch &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {branchname}
                </a>
              </Dropdown.Item>
              <Dropdown.Item eventKey="3">
                <a
                  className="dropdown-item"
                  href="#"
                  style={{
                    fontSize: '12px',
                    border: '1px solid #ccc',
                    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <FontAwesomeIcon icon={faUser} style={{ color: 'purple' }} /> <span className="fontwt">User </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{username}
                </a>
              </Dropdown.Item>

              <Dropdown.Item eventKey="4">
                <button className="btn btn-success" onClick={handleAddClick} style={{ fontSize: '12px', marginRight: 20 }}>
                  <FontAwesomeIcon icon={faRefresh} /> Change Password
                </button>
                <button className="btn btn-danger" onClick={handleLogout} style={{ fontSize: '12px' }}>
                  <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </button>
              </Dropdown.Item>
            </SplitButton>
          </div>
        </Container>
      </Navbar>









      <Modal show={show} onHide={handleClose} size='xl'>

        <Card style={{ backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-wavy-background_23-2149123392.jpg?t=st=1694859409~exp=1694860009~hmac=b397945a9c2d45405ac64956165f76bd10a0eff99334c52cd4c88d4162aad58e)', backgroundSize: 'cover' }}>
          <CardBody>

            <h4 className="pageHead text-center" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
              icon={faMoneyBill}
              style={{
                marginRight: '8px',
                color: 'black',
              }}
            />CreditLimit and Pending Balance</h4>
            <button
              className="close-button"
              onClick={handleClose}
            >
              <FontAwesomeIcon
                icon={faTimes}
              />
            </button>


            <div className="table-responsive">
              <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                <thead style={{ backgroundColor: 'rgb(226 232 240)' }}>
                  <tr className="text-center" style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px' }}>
                    <th style={{ backgroundColor: '#BADDDA', height: '50px',fontWeight: 'bold', border: '2px solid black' }}>Sr No</th>
                    <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black' }}>Party Id</th>
                    <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black' }}>Party Code</th>
                    <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black' }}>Party Name</th>
                    <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black' }}>Credit Limit</th>
                    <th style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black' }}>Pending Amount</th>
                  </tr>
                </thead>
                <tbody>

                  {currentfilteredImports.map((imports, index) =>
                    <tr className="text-center" key={imports[0]}>
                      <td className={`text-center`}>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
                      <td className="table-column">{imports[0]}</td>
                      <td className="table-column">{imports[1]}</td>
                      <td className="table-column">{imports[2]}</td>
                      <td className="table-column">{imports[3]}</td>
                      <td className="table-column">{imports[4]}</td>
                    </tr>
                  )}
                </tbody>
              </Table>


              <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                <Pagination.First onClick={() => handlePageChange(1)} />
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Ellipsis />

                {displayPages().map((pageNumber) => (
                  <Pagination.Item
                    key={pageNumber}
                    active={pageNumber === currentPage}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </Pagination.Item>
                ))}

                <Pagination.Ellipsis />
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                <Pagination.Last onClick={() => handlePageChange(totalPages)} />
              </Pagination>
            </div>




          </CardBody>
        </Card>

      </Modal>

    </div>

  )
}


