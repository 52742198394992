import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import { Button, Pagination } from 'react-bootstrap';
import "../Components/Style.css";
import dgdcImage from "../Images/report.jpeg";
import DatePicker from "react-datepicker";
import ipaddress from "../Components/IpAddress";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Table,
} from "reactstrap";
import Rate_Chart_Service from "../services/Rate_Chart_Service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faRefresh, faFilePdf, faUserCircle, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import ReactLoading from 'react-loading';
import Select from 'react-select';
import InviceService from "../services/InviceService";
import moment from 'moment';

export default function DailySummaryReport() {

  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999, // Ensure the overlay is above other elements
    },
  };


  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  useEffect(() => {
    if (!isAuthenticated) {
      navigate(
        "/login?message=You need to be authenticated to access this page."
      );
    }
  }, [isAuthenticated, navigate]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedParty, setSelectedParty] = useState(null);
  const [Invoices, setInvoices] = useState([]);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const [startDate2, setStartDate2] = useState(new Date());
  const [endDate2, setEndDate2] = useState(new Date());
  const [selectedParty2, setSelectedParty2] = useState(null);
  const [selectedPartyId, setSelectedPartyId] = useState('');


  const [Invoices2, setInvoices2] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDateTime2 = (value) => {
    if (!value) {
      return ""; // Return an empty string if value is empty or undefined
    }

    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Function to calculate the total for a specific column
  const calculateTotal = (data, columnIndex) => {
    const total = data.reduce((sum, row) => sum + (parseFloat(row[columnIndex]) || 0), 0);
  
    // Check if the total has a fractional part
    const roundedTotal = total % 1 !== 0 ? parseFloat(total.toFixed(2)) : total;
  
    return roundedTotal !== 0 ? roundedTotal : ''; // Return the rounded total or an empty string if zero
  };


  const calculateTotalParcelType = (data, columnIndex, parcelType) => {
    console.log('parcelType',parcelType);
    
    // Filter data based on parcel type
    const filteredData = data.filter(row => row[1] === parcelType); // Assuming index 1 contains the parcel type
  
    // Calculate total for the specified column index
    const total = filteredData.reduce((sum, row) => sum + (parseFloat(row[columnIndex]) || 0), 0);
  
    // Check if the total has a fractional part
    const roundedTotal = total % 1 !== 0 ? parseFloat(total.toFixed(2)) : total;
  
    return roundedTotal !== 0 ? roundedTotal : ''; // Return the rounded total or an empty string if zero
  };
  
  


  const calculateTotalColoumn = (data, columnName) => {
    const total = data.reduce((sum, row) => sum + (parseFloat(row[columnName]) || 0), 0);
    return total.toFixed(2); // Round the total to two decimal places
  };




  const { branchId, companyid } = useContext(AuthContext);

  const handleReset = () => {
    setSearchCriteria({ ...searchCriteria, startDate: new Date().toISOString() });
    setSearchCriteria({ ...searchCriteria, endDate: new Date().toISOString() });
    setInvoices([]);
  };



  const handlePrint = async (type) => {
    try {
      const response = await InviceService.generatePartyDailyInvoiceBillSummary(searchCriteria);

      if (response.status === 200) {
        const pdfData = response.data;
        const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(pdfBlob);

        if (type === "PDF") {
          // Create an anchor element for downloading
          const downloadLink = document.createElement('a');
          downloadLink.href = blobUrl;
          downloadLink.download = 'PartyDailyBillSummary.pdf'; // Set the filename for the downloaded PDF
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          // Trigger the download
          downloadLink.click();
          // Clean up
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(blobUrl);
        }
        if (type === 'PRINT') {
          window.open(blobUrl, '_blank');
        }
        toast.success("Downloading Pdf!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 800,
        });
      } else {
        toast.error("error downLoading file!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 800,
        });
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleXLSdownLoad = async () => {
    setLoading(true);
    try {
      const response = await InviceService.downLoadDailyInvoiceSummaryXLS(searchCriteria);

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = 'PartyDailyBillSummary.xlsx';
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
    catch {
      toast.error("error downLoading file!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    }
    finally {
      setLoading(false);
    }
  };


  // SHB
  const customFilterOption = (candidate, input) => {
    const inputLower = input.toLowerCase();
    const labelLower = candidate.label.toLowerCase();
    return candidate.data.__isNew__ || labelLower.startsWith(inputLower);
  };

  useEffect(() => {
    findPartiesAll();
  }, [])

  const [partiesAll, setPartiesAll] = useState([]);
  const [selectedPartyTransaction, setSelectedPartyTransaction] = useState(null);
  const [selectedPartyDaily, setSelectedPartyDaily] = useState(null);

  const findPartiesAll = async () => {
    const partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);
    const partyOptions = partyResponse.data.map(party => ({
      value: party[0],
      label: party[1]
    }));
    setPartiesAll(partyOptions);

  };

  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };
  const currentDate = new Date();
  const endDate1 = new Date(currentDate);
  // endDate1.setDate(endDate1.getDate() - 1);
  const startDate1 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  const initialSearchCriteria = {
    companyid: companyid,
    branchId: branchId,
    PartyId: '',
    startDate: formatDate(startDate1),
    endDate: formatDate(endDate1),
  };
  const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);

  const [searchCriteriaDaily, setSearchCriteriaDaily] = useState(initialSearchCriteria);

  const handlePartyTransaction = async (selectedOption) => {
    setSelectedPartyTransaction(selectedOption ? selectedOption : null);
    setSearchCriteria({ ...searchCriteria, PartyId: selectedOption ? selectedOption.value : null });
    if (!selectedOption) {
      setInvoices([]);
    }
  };



  const handleSearch = async () => {
    setInvoices([]);
    setLoading(true);
    try {
      const results = await InviceService.getPartyDailyInvoiceSummaryReport(searchCriteria);
      if (!results.data || results.data.length === 0) {
        toast.info('No data found', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 600,
        });
      } else {
        setInvoices(results.data);
      }
    } catch (error) {
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 600,
      });
    }
    finally {
      setLoading(false);
    }
  };



  const handlePartyDaily = async (selectedOption) => {
    setSelectedPartyDaily(selectedOption ? selectedOption : null);
    setSearchCriteriaDaily({ ...searchCriteriaDaily, PartyId: selectedOption ? selectedOption.value : null });
    if (!selectedOption) {
      setInvoices2([]);
    }
  };



  // Pagination 
  const [currentPage5, setCurrentPage5] = useState(1);
  const itemsPerPage5 = 62; // Number of items to display per page

  const indexOfLastItem5 = currentPage5 * itemsPerPage5;
  const indexOfFirstItem5 = indexOfLastItem5 - itemsPerPage5;
  const currentItems5 = Invoices2.slice(indexOfFirstItem5, indexOfLastItem5);
  const totalPages5 = Math.ceil(Invoices2.length / itemsPerPage5);

  const handlePageChange5 = (page) => {
    setCurrentPage5(page);
  };

  const displayPages5 = () => {
    const centerPageCount = 5;
    const middlePage = Math.floor(centerPageCount / 2);
    let startPage = currentPage5 - middlePage;
    let endPage = currentPage5 + middlePage;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages5, centerPageCount);
    }

    if (endPage > totalPages5) {
      endPage = totalPages5;
      startPage = Math.max(1, totalPages5 - centerPageCount + 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  const handleSearch2 = async () => {

    setCurrentPage5(1);
    setInvoices2([]);
    setLoading(true);
    try {

      const results = await InviceService.getPartyDailyInvoiceDailyReport(searchCriteriaDaily);
      if (!results.data || results.data.length === 0) {
        toast.info('No data found', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 600,
        });
      } else {
        setInvoices2(results.data);
              // setNoRecordsFound2(results.data.length === 0);
      }
    } catch (error) {
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 600,
      });
    }
    finally {
      setLoading(false);
    }
  };

  const handleReset2 = () => {
    setSelectedPartyDaily(null);
    setInvoices2([]);
    setSearchCriteriaDaily(initialSearchCriteria);
  };



  // Daily Report XLS downLoad
  const handleXLSdownLoadDaily = async () => {
    setLoading(true);
    try {
      const response = await InviceService.getPartyDailyInvoiceDailyReportXLS(searchCriteriaDaily);

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = 'Daily_Bill_Report.xlsx';
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
    catch {
      toast.error("error downLoading file!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    }
    finally {
      setLoading(false);
    }
  };

  

  const groupedData = currentItems5.reduce((acc, item) => {
    const date = formatDateTime2(item[0]);
    if (!acc[date]) acc[date] = [];
    acc[date].push(item);
    return acc;
  }, {});

console.log(" Invoices2 : \n",Invoices2);



  return (
    <>
      {loading && (
        <div style={styles.overlay}>
          <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
        </div>
      )}

      <div className='' style={{ marginTop: 20 }}>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item tabspace" role="presentation">
            <button style={{ color: 'gray' }} className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><h6>Summary Report</h6></button>
          </li>

          <li className="nav-item tabspace" role="presentation">
            <button style={{ color: 'gray' }} className="nav-link" id="transaction-tab" data-bs-toggle="tab" data-bs-target="#transaction" type="button" role="tab" aria-controls="transaction" aria-selected="false"><h6>Daily Report</h6></button>
          </li>


        </ul>

        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

            <div className="Container">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <Label className="forlabel" for="branchId">Select Party</Label>
                        <Select
                          options={partiesAll}
                          value={selectedPartyTransaction}
                          onChange={handlePartyTransaction}
                          isClearable
                          filterOption={customFilterOption}
                          placeholder="Select a Party"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                              boxShadow: 'none',
                              '&:hover': {
                                border: '1px solid #ccc'
                              }
                            }),
                            indicatorSeparator: () => ({
                              display: 'none'
                            }),
                            dropdownIndicator: () => ({
                              display: 'none'
                            })
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label className="forlabel" for="branchId">From Date</Label>
                        <div>
                          <DatePicker
                            selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                           
                            onChange={(date) => {
                              if (date) {
                                setSearchCriteria({ ...searchCriteria, startDate: date.toISOString() });
                              } else {
                                setSearchCriteria({ ...searchCriteria, startDate: null });
                              }
                            }}
                            dateFormat="dd/MM/yyyy" // Specify the combined format
                            className="form-control"
                            wrapperClassName="custom-react-datepicker-wrapper"
                            // maxDate={endDate1}
                            customInput={<input style={{ width: '100%' }} />}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label className="forlabel" for="branchId">To Date</Label>
                        <div>
                          <DatePicker
                            selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null}
                            wrapperClassName="custom-react-datepicker-wrapper"
                            onChange={(date) => {
                              if (date) {
                                setSearchCriteria({ ...searchCriteria, endDate: date.toISOString() });
                              } else {
                                setSearchCriteria({ ...searchCriteria, endDate: null });
                              }
                            }}
                            dateFormat="dd/MM/yyyy" // Specify the combined format
                            className="form-control"
                            // maxDate={endDate1}
                            customInput={<input style={{ width: '100%' }} />}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={3} style={{ marginTop: 22 }}>
                      <Button
                        type="button"
                        className=""
                        variant="outline-primary"
                        style={{ marginTop: "10px", marginRight: 10, fontWeight: 'bold' }}
                        onClick={handleSearch}
                      >
                        <FontAwesomeIcon
                          icon={faSearch}
                          style={{ marginRight: "5px" }}
                        />
                        Search
                      </Button>
                      <Button
                        type="button"
                        className=""
                        variant="outline-danger"
                        style={{ marginTop: "10px", fontWeight: 'bold' }}
                        onClick={handleReset}
                      >
                        <FontAwesomeIcon
                          icon={faRefresh}
                          style={{ marginRight: "5px" }}
                        />
                        Reset
                      </Button>
                    </Col>
                  </Row>


                  {Invoices.length > 0 ? (

                    <>
                      <Row>
                        <Col className="text-end mt-1">
                          <Button
                            type="submit"
                            className=""
                            style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
                            variant="outline-success"
                            onClick={() => handlePrint("PRINT")}
                          >
                            <FontAwesomeIcon
                              icon={faPrint}
                              style={{ marginRight: "5px" }}
                            />
                            Print
                          </Button>

                          <Button
                            type="button"
                            className=""
                            style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
                            variant="outline-primary"
                            onClick={() => handlePrint("PDF")}
                          >
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              style={{ marginRight: "5px" }}
                            />
                            PDF
                          </Button>

                          <Button
                            type="button"
                            className=""
                            style={{ marginLeft: 5, fontWeight: 'bold' }}
                            variant="outline-success"
                            onClick={handleXLSdownLoad}
                          >
                            <FontAwesomeIcon
                              icon={faFileExcel}
                              style={{ marginRight: "5px" }}
                            />
                            XLS
                          </Button>
                        </Col>

                      </Row>

                      <div className="table-responsive">
                        <hr />


                        <Table className="table table-bordered table-hover mt-1" style={{ border: '2px solid black' }}>
                          <thead className="thead-dark bg-dark">
                            <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
                              <th rowSpan="2" style={{ width: '3%', background: '#BADDDA' }}>Sr No.</th>
                              <th rowSpan="2" style={{ width: '10%', background: '#BADDDA' }}>Party</th>
                              <th rowSpan="2" style={{ width: '10%', background: '#BADDDA' }}>GST No</th>
                              <th rowSpan="2" style={{ width: '8%', background: '#BADDDA' }}>IEC No</th>
                              <th rowSpan="2" style={{ width: '5%', background: '#BADDDA' }}>Doc Charges</th>
                              <th colSpan="2" style={{ width: '15%', background: '#BADDDA' }}>As Per Value(INR)</th>
                              <th colSpan="2" style={{ width: '15%', background: '#BADDDA' }}>As Per Weight</th>
                              <th colSpan="2" style={{ width: '8%', background: '#BADDDA' }}>Demurages Charges(INR)</th>
                              <th rowSpan="2" style={{ width: '15%', background: '#BADDDA' }}>Total Amount(INR)</th>
                              <th colSpan="3" style={{ width: '10%', background: '#BADDDA' }}>GST(INR)</th>
                              <th rowSpan="2" style={{ width: '15%', background: '#BADDDA' }}>Grand Total(INR)</th>
                            </tr>
                            <tr className="text-center">
                              <th style={{ width: '6%', background: '#BADDDA' }}>Value</th>
                              <th style={{ width: '6%', background: '#BADDDA' }}>Charges</th>
                              <th style={{ width: '6%', background: '#BADDDA' }}>Weight(CTS)</th>
                              <th style={{ width: '6%', background: '#BADDDA' }}>Charges(INR)</th>
                              <th style={{ width: '6%', background: '#BADDDA' }}>Days</th>
                              <th style={{ width: '6%', background: '#BADDDA' }}>Charges</th>
                              <th style={{ width: '6%', background: '#BADDDA' }}>SGST</th>
                              <th style={{ width: '6%', background: '#BADDDA' }}>CGST</th>
                              <th style={{ width: '6%', background: '#BADDDA' }}>IGST</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Invoices.map((import2, index) =>
                              <tr className="text-center">
                                <td>{index + 1}</td>
                                <td>{import2[0]}</td>
                                <td>{import2[1]}</td>
                                <td>{import2[2]}</td>
                                <td>{import2[3]}</td>
                                <td>{import2[4]}</td>
                                <td>{import2[5]}</td>
                                <td>{import2[6]}</td>
                                <td>{import2[7]}</td>
                                <td>{import2[8]}</td>
                                <td>{import2[9]}</td>
                                <td>{import2[10]}</td>
                                <td>{import2[11]}</td>
                                <td>{import2[12]}</td>
                                <td>{import2[13]}</td>
                                <td>{import2[14]}</td>
                              </tr>
                            )}
                            <tr className="text-center dynamic-row-width total-row">
                              <td>Total</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{calculateTotal(Invoices, 3)}</td>
                              <td>{calculateTotal(Invoices, 4)}</td>
                              <td>{calculateTotal(Invoices, 5)}</td>
                              <td>{calculateTotal(Invoices, 6)}</td>
                              <td>{calculateTotal(Invoices, 7)}</td>
                              <td></td>
                              <td>{calculateTotal(Invoices, 9)}</td>
                              
                              <td>{calculateTotal(Invoices, 10)}</td>
                              <td>{calculateTotal(Invoices, 11)}</td>
                              <td>{calculateTotal(Invoices, 12)}</td>
                              <td>{calculateTotal(Invoices, 13)}</td>
                              <td>{calculateTotal(Invoices, 14)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : null}

                </CardBody>
              </Card>
            </div>
          </div>




          <div className="tab-pane fade show " id="transaction" role="tabpanel" aria-labelledby="transaction-tab">

            <Card>
              <CardBody>
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <Label className="forlabel" for="branchId">From Date</Label>
                      <div>
                        <DatePicker
                          selected={searchCriteriaDaily.startDate ? new Date(searchCriteriaDaily.startDate) : null}
                          wrapperClassName="custom-react-datepicker-wrapper"
                          onChange={(date) => {
                            if (date) {
                              setSearchCriteriaDaily({ ...searchCriteriaDaily, startDate: date.toISOString() });
                            } else {
                              setSearchCriteriaDaily({ ...searchCriteriaDaily, startDate: null });
                            }
                          }}
                          dateFormat="dd/MM/yyyy" // Specify the combined format
                          className="form-control"
                          // maxDate={endDate1}
                          customInput={<input style={{ width: '100%' }} />}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label className="forlabel" for="branchId">To Date</Label>
                      <div>
                        <DatePicker
                          selected={searchCriteriaDaily.endDate ? new Date(searchCriteriaDaily.endDate) : null}
                          wrapperClassName="custom-react-datepicker-wrapper"
                          onChange={(date) => {
                            if (date) {
                              setSearchCriteriaDaily({ ...searchCriteriaDaily, endDate: date.toISOString() });
                            } else {
                              setSearchCriteriaDaily({ ...searchCriteriaDaily, endDate: null });
                            }
                          }}
                          dateFormat="dd/MM/yyyy" // Specify the combined format
                          className="form-control"
                          // maxDate={endDate1}
                          customInput={<input style={{ width: '100%' }} />}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Label className="forlabel" for="branchId">Select Party</Label>
                      <Select
                        options={partiesAll}
                        value={selectedPartyDaily}
                        onChange={handlePartyDaily}
                        isClearable
                        filterOption={customFilterOption}
                        placeholder="Select a Party"
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                            boxShadow: 'none',
                            '&:hover': {
                              border: '1px solid #ccc'
                            }
                          }),
                          indicatorSeparator: () => ({
                            display: 'none'
                          }),
                          dropdownIndicator: () => ({
                            display: 'none'
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3} style={{ marginTop: 22 }}>
                    <Button
                      type="button"
                      className=""
                      variant="outline-primary"
                      style={{ marginTop: "10px", marginRight: 10, fontWeight: 'bold' }}
                      onClick={(e) => handleSearch2()}
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ marginRight: "5px" }}
                      />
                      Search
                    </Button>
                    <Button
                      type="button"
                      className=""
                      variant="outline-danger"
                      style={{ marginTop: "10px", fontWeight: 'bold' }}
                      onClick={handleReset2}
                    >
                      <FontAwesomeIcon
                        icon={faRefresh}
                        style={{ marginRight: "5px" }}
                      />
                      Reset
                    </Button>
                  </Col>
                </Row>
                {/* </CardBody>
</Card> */}
                {!Invoices2 || Invoices2.length === 0 && (
                  <div className="no-records-found text-center">
                    <p style={{ color: 'red' }}>
                      No records found
                    </p>
                  </div>
                )}


                {Invoices2 && Invoices2.length > 0 && (
                  <>
                    <Row>
                      <Col className="text-end mt-1">
                        <Button
                          type="button"
                          className=""
                          style={{ fontWeight: 'bold' }}
                          variant="outline-success"
                          onClick={() => handleXLSdownLoadDaily()}
                        >
                          <FontAwesomeIcon
                            icon={faFileExcel}
                            style={{ marginRight: "5px" }}
                          />
                          XLS
                        </Button>
                      </Col>
                    </Row>
                    <div className="table-responsive">
                      <hr />

                      <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                        <thead className="thead-dark bg-dark">
                          <tr className="text-center" style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
                            <th rowSpan="2" style={{ width: '5%', background: '#BADDDA' }}>Invoice Date</th>
                            <th rowSpan="2" style={{ width: '5%', background: '#BADDDA' }}>Type</th>
                            <th rowSpan="2" style={{ width: '6%', background: '#BADDDA' }}>PKT</th>
                            <th rowSpan="2" style={{ width: '6%', background: '#BADDDA' }}>PCL</th>
                            <th rowSpan="2" style={{ width: '25%', background: '#BADDDA' }}>Doc Charges</th>
                            <th colSpan="2" style={{ width: '25%', background: '#BADDDA' }}>As Per Value</th>
                            <th colSpan="2" style={{ width: '25%', background: '#BADDDA' }}>As Per Weight</th>
                            <th rowSpan="2" style={{ width: '25%', background: '#BADDDA' }}>Demurages Charges</th>
                            <th rowSpan="2" style={{ width: '25%', background: '#BADDDA' }}>Total Amount(INR)</th>
                            <th rowSpan="2" style={{ width: '10%', background: '#BADDDA' }}>Tax Amount(INR)</th>
                            <th rowSpan="2" style={{ width: '15%', background: '#BADDDA' }}>Total Invoice Amount(INR)</th>
                            <th rowSpan="2" style={{ width: '15%', background: '#BADDDA' }}>Total Invoice Amount(INR) + Demurage</th>
                          </tr>
                          <tr className="text-center">                            
                            <th style={{ width: '6%', background: '#BADDDA' }}>Value(INR)</th>
                            <th style={{ width: '6%', background: '#BADDDA' }}>Charges(INR)</th>
                            <th style={{ width: '6%', background: '#BADDDA' }}>Weight(CTS)</th>
                            <th style={{ width: '6%', background: '#BADDDA' }}>Charges(INR)</th>

                          </tr>
                        </thead>                        
                        <tbody>
                            {Object.keys(groupedData).map((date, index) => {
                              const rows = groupedData[date];
                              const mergedRow = rows.reduce((acc, row) => {
                                acc[2] += row[2]; // Sum of PKT
                                acc[3] += row[3]; // Sum of PCL

                                acc[4] += row[4]; // Sum of PKT
                                acc[5] += row[5]; // Sum of PCL

                                acc[6] += row[6]; // Sum of PKT
                                acc[7] += row[7]; // Sum of PCL


                                acc[8] += row[8]; // Sum of PKT
                                acc[9] += row[9]; // Sum of PCL

                                acc[10] += row[10]; // Sum of PKT
                                acc[11] += row[11]; // Sum of PCL
                                acc[12] += row[12]; // Sum of Total Amount(INR)

                                // acc[13] += row[13]; // Sum of Total Amount(INR)
                                return acc;
                              }, Array(rows[0].length).fill(0));

                              for (let i = 0; i < mergedRow.length; i++) {
                                if (typeof mergedRow[i] === 'number' && mergedRow[i] % 1 !== 0) {
                                  mergedRow[i] = Math.round(mergedRow[i] * 100) / 100;                                }
                              }
                              mergedRow[0] = date; // Set the date
                              mergedRow[1] = 'Total'; // Clear type for the merged row

                              return (
                                <React.Fragment key={index}>
                                  {rows.map((row, i) => (
                                    <tr key={i} className="text-center dynamic-row-width" style={{ height: '40px' }}>
                                      {i === 0 && (
                                        <td rowSpan={rows.length + 1} className="centered-cell">{date}</td>
                                      )}
                                      <td>{row[1]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[4]}</td>
                                      <td>{row[5]}</td>
                                      <td>{row[6]}</td>
                                      <td>{row[7]}</td>
                                      <td>{row[8]}</td>
                                      <td>{row[9]}</td>
                                      <td>{row[10]}</td>
                                      <td>{row[11]}</td>
                                      <td>{row[12]}</td> 
                                      <td>{row[9] + row[12]}</td>                                                                                                               
                                    </tr>
                                  ))}
                                  <tr className="text-center dynamic-row-width" style={{ height: '40px', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                                    <td>Total</td>
                                    <td>{mergedRow[2]}</td>
                                    <td>{mergedRow[3]}</td>
                                    <td>{mergedRow[4]}</td>
                                    <td>{mergedRow[5]}</td>
                                    <td>{mergedRow[6]}</td>
                                    <td>{mergedRow[7]}</td>
                                    <td>{mergedRow[8]}</td>
                                    <td>{mergedRow[9]}</td>
                                    <td>{mergedRow[10]}</td>
                                    <td>{mergedRow[11]}</td>                                    
                                    <td>{mergedRow[12]}</td>
                                    <td>{mergedRow[9] + mergedRow[12]}</td>
                                                                      
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          </tbody>


                          <tr className="text-center dynamic-row-width total-row">
                            <td rowSpan={3}>Grand Total</td>
                            <td>EXP</td>
                            <td>{calculateTotalParcelType(Invoices2, 2,'Exp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 3,'Exp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 4,'Exp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 5,'Exp')}</td>

                            <td>{calculateTotalParcelType(Invoices2, 6,'Exp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 7,'Exp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 8,'Exp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 9,'Exp')}</td> 
                            <td>{calculateTotalParcelType(Invoices2, 10,'Exp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 11,'Exp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 12,'Exp')}</td>
                            <td>{ calculateTotalParcelType(Invoices2, 9,'Exp') + calculateTotalParcelType(Invoices2, 12,'Exp')}</td>      
                                                      

                          </tr>

                          <tr className="text-center dynamic-row-width total-row">
                            <td>IMP</td>
                            <td>{calculateTotalParcelType(Invoices2, 2,'Imp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 3,'Imp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 4,'Imp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 5,'Imp')}</td>

                            <td>{calculateTotalParcelType(Invoices2, 6,'Imp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 7,'Imp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 8,'Imp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 9,'Imp')}</td> 
                            <td>{calculateTotalParcelType(Invoices2, 10,'Imp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 11,'Imp')}</td>
                            <td>{calculateTotalParcelType(Invoices2, 12,'Imp')}</td>  
                            <td>{ calculateTotalParcelType(Invoices2, 9,'Imp') + calculateTotalParcelType(Invoices2, 12,'Imp')}</td>                           

                          </tr>
                          <tr className="text-center dynamic-row-width total-row">
                            <td>Total</td>
                            <td>{calculateTotal(Invoices2, 2)}</td>
                            <td>{calculateTotal(Invoices2, 3)}</td>
                            <td>{calculateTotal(Invoices2, 4)}</td>
                            <td>{calculateTotal(Invoices2, 5)}</td>

                            <td>{calculateTotal(Invoices2, 6)}</td>
                            <td>{calculateTotal(Invoices2, 7)}</td>
                            <td>{calculateTotal(Invoices2, 8)}</td>
                            <td>{calculateTotal(Invoices2, 9)}</td> 
                            <td>{calculateTotal(Invoices2, 10)}</td>
                            <td>{calculateTotal(Invoices2, 11)}</td>
                            <td>{calculateTotal(Invoices2, 12)}</td>  
                            <td>{ calculateTotal(Invoices2, 9) + calculateTotal(Invoices2, 12)}</td>                   

                          </tr>

                        </Table>
                        <div className="text-center">

                          <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                            <Pagination.First onClick={() => handlePageChange5(1)} />
                            <Pagination.Prev
                              onClick={() => handlePageChange5(currentPage5 - 1)}
                              disabled={currentPage5 === 1}
                            />
                            <Pagination.Ellipsis />

                            {displayPages5().map((pageNumber) => (
                              <Pagination.Item
                                key={pageNumber}
                                active={pageNumber === currentPage5}
                                onClick={() => handlePageChange5(pageNumber)}
                              >
                                {pageNumber}
                              </Pagination.Item>
                            ))}

                            <Pagination.Ellipsis />
                            <Pagination.Next
                              onClick={() => handlePageChange5(currentPage5 + 1)}
                              disabled={currentPage5 === totalPages5}
                            />
                            <Pagination.Last onClick={() => handlePageChange5(totalPages5)} />
                          </Pagination>
                        </div>
                    </div>


                  </>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}